body {
  background-color: #fffaee;
}
.section-header {
  padding-left: 50px;
  padding-right: 50px;
  text-align: justify;
}
.black {
  color: black;
  text-align: center;
  font-size: 14px;
  font-weight: 700;
}
.op-1 {
  color: black !important;
}
.p-2r {
  padding: 2rem !important;
}
.border {
  border: 1px solid #788188 !important;
}
.border-l {
  /* border-left: 1px solid #dee2e6 !important; */
  border-left: 1px solid #788188 !important;
}
.border-b {
  border-bottom: 1px solid #dee2e6 !important;
}
.resp {
  width: 49%;
  margin-left: 22rem;
}
.card {
  margin: 20px;
  background: transparent;
}
.button {
  display: block;
  text-align: center;
  padding: 3px 15px;
  width: 100%;
  -webkit-appearance: media-slider;
  /* margin: 10px; */
  /* background: red; */
  font-size: 18px;
  background-color: #fffaee;
  margin-bottom: 15px;
  box-shadow: 0 1px 2px rgba(0, 0, 0, 0.3);
  cursor: pointer;
  border: none;
}
@media (max-width: 1025px) {
  .resp {
    margin-left: 18rem;
  }
}
@media (max-width: 769px) {
  .resp {
    width: 52%;
    margin-left: 13rem;
  }
}
/* Extra small devices (phones, 600px and down) */
@media only screen and (max-width: 600px) {
  .m-border-t {
    border-top: 1px solid #dee2e6 !important;
  }
  .m-border-b {
    border-bottom: 1px solid #dee2e6 !important;
  }
  .border-l {
    border-left: 0px solid #dee2e6 !important;
  }
  .border {
    border: 0px !important;
  }
  .resp {
    width: auto;
    margin-left: 3rem;
  }
  .half-col {
    width: 45%;
  }
  .card-resp {
    width: 100%;
    height: 240px;
    max-height: 350px;
  }
  .img-resp {
    height: 100%;
  }
  .quantity-button-1 {
    width: 20%;
  }
  .quantity-button-2 {
    width: 20%;
  }
  .quantity-label {
    width: 60%;
  }
}

img {
  /* display: block; */
  max-width: 100%;
  max-height: 100%;
}

.App__header {
  /* background-color: #222;
  background-image: url('https://unsplash.it/1000/300?image=823');
  background-size: cover; */
  color: black;
  padding: 10px 10px;
}

.App__nav {
  width: 100%;
  list-style: none;
}

.App__customer-actions {
  float: left;
  padding: 10px;
}

.App__title {
  padding: 80px 20px;
  text-align: center;
}

/* .Product-wrapper {
  max-width: 900px;
  margin: 40px auto 0;
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
} */

.App__view-cart-wrapper {
  float: right;
}

.App__view-cart {
  font-size: 15px;
  border: none;
  background: none;
  cursor: pointer;
  color: black;
}

/* .button {
  background-color: #2752ff;
  color: white;
  border: none;
  font-size: 1.2rem;
  padding: 10px 17px;
  cursor: pointer;
}

.button:hover,
.button:focus {
  background-color: #222222;
}

.button:disabled {
  background: #bfbfbf;
  cursor: not-allowed;
}

.login {
  font-size: 1.2rem;
  color: #b8b8b8;
  cursor: pointer;
}

.login:hover {
  color: white;
}

.Flash__message-wrapper {
  -webkit-justify-content: center;
  -ms-flex-pack: center;
  align-items: flex-end;
  justify-content: center;
  position: fixed;
  bottom: 0;
  pointer-events: none;
  z-index: 227;
  left: 50%;
  transform: translateX(-50%);
}

.Flash__message {
  background: rgba(0, 0, 0, 0.88);
  border-radius: 3px;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
  color: #ffffff;
  cursor: default;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: none;
  pointer-events: auto;
  position: relative;
  font-size: 20px;
  line-height: 28px;
  font-weight: 400;
  padding: 10px 20px;
  margin: 0;
}

.Flash__message--open {
  display: flex;
} */

/* CART
 * ============================== */
.Cart {
  position: fixed;
  top: 0;
  right: 0;
  height: 100%;
  width: 350px;
  background-color: white;
  display: flex;
  flex-direction: column;
  border-left: 1px solid #e5e5e5;
  transform: translateX(100%);
  transition: transform 0.15s ease-in-out;
  z-index: 9999;
}

.Cart--open {
  transform: translateX(0);
}

.Cart__close {
  position: absolute;
  right: 9px;
  top: 8px;
  font-size: 35px;
  color: #999;
  border: none;
  background: transparent;
  transition: transform 100ms ease;
  cursor: pointer;
}

.Cart__header {
  padding: 20px;
  border-bottom: 1px solid #e5e5e5;
  flex: 0 0 auto;
  display: inline-block;
}

.Cart__line-items {
  flex: 1 0 auto;
  margin: 0;
  padding: 20px;
}

.Cart__footer {
  padding: 20px;
  border-top: 1px solid #e5e5e5;
  flex: 0 0 auto;
}

.Cart__checkout {
  margin-top: 20px;
  display: block;
  width: 100%;
}

.Cart-info {
  padding: 15px 20px 10px;
}

.Cart-info__total {
  float: left;
  text-transform: uppercase;
}

.Cart-info__small {
  font-size: 11px;
}

.Cart-info__pricing {
  float: right;
}

.pricing {
  margin-left: 5px;
  font-size: 16px;
  color: black;
}

/* LINE ITEMS
 * ============================== */
.Line-item {
  margin-bottom: 20px;
  overflow: hidden;
  backface-visibility: visible;
  min-height: 65px;
  position: relative;
  opacity: 1;
  transition: opacity 0.2s ease-in-out;
}

.Line-item__img {
  width: 65px;
  height: 65px;
  border-radius: 3px;
  background-size: contain;
  background-repeat: no-repeat;
  background-position: center center;
  background-color: #e5e5e5;
  position: absolute;
}

.Line-item__content {
  width: 100%;
  padding-left: 75px;
}

.Line-item__content-row {
  display: inline-block;
  width: 85%;
  margin-top: 15px;
  position: relative;
}

.Line-item__variant-title {
  float: right;
  font-weight: bold;
  font-size: 11px;
  line-height: 17px;
  color: #767676;
}

.Line-item__title {
  color: #4e5665;
  font-size: 15px;
  font-weight: 400;
}

.Line-item__price {
  line-height: 23px;
  float: right;
  font-weight: bold;
  font-size: 15px;
  margin-right: 40px;
}

.Line-item__quantity-container {
  border: 1px solid #767676;
  float: left;
  border-radius: 3px;
}

.Line-item__quantity-update {
  color: #767676;
  display: block;
  float: left;
  height: 21px;
  line-height: 16px;
  font-family: monospace;
  width: 25px;
  padding: 0;
  border: none;
  background: transparent;
  box-shadow: none;
  cursor: pointer;
  font-size: 18px;
  text-align: center;
}

.Line-item__quantity-update-form {
  display: inline;
}

.Line-item__quantity {
  color: black;
  width: 38px;
  height: 21px;
  line-height: 23px;
  font-size: 15px;
  border: none;
  text-align: center;
  -moz-appearance: textfield;
  background: transparent;
  border-left: 1px solid #767676;
  border-right: 1px solid #767676;
  display: block;
  float: left;
  padding: 0;
  border-radius: 0;
}

.Line-item__remove {
  position: absolute;
  right: 0;
  top: 0;
  border: 0;
  background: 0;
  font-size: 20px;
  top: -4px;
  opacity: 0.5;
}

.Line-item__remove:hover {
  opacity: 1;
  cursor: pointer;
}

/* PRODUCTS
 * ============================== */
/* .Product {
  flex: 0 1 31%;
  margin-left: 1%;
  margin-right: 1%;
  margin-bottom: 3%;
} */

/* .Product__title {
  font-size: 1.3rem;
  margin-top: 1rem;
  margin-bottom: 0.4rem;
  opacity: 0.7;
}

.Product__price {
  display: block;
  font-size: 1.1rem;
  opacity: 0.5;
  margin-bottom: 0.4rem;
} */

.Product__option {
  display: block;
  width: 100%;
  margin-bottom: 10px;
  background: transparent;
}

/* .Product__quantity {
  display: block;
  width: 15%;
  margin-bottom: 10px;
} */

/* CUSTOMER AUTH
 * ============================== */
.CustomerAuth {
  background: #2a2c2e;
  display: none;
  height: 100%;
  left: 0;
  opacity: 0;
  padding: 0 0 65px;
  top: 0;
  width: 100%;
  text-align: center;
  color: #fff;
  transition: opacity 150ms;
  opacity: 1;
  visibility: visible;
  z-index: 1000;
  position: fixed;
}

.CustomerAuth--open {
  display: block;
}

.CustomerAuth__close {
  position: absolute;
  right: 9px;
  top: 8px;
  font-size: 35px;
  color: #999;
  border: none;
  background: transparent;
  transition: transform 100ms ease;
  cursor: pointer;
}

.CustomerAuth__body {
  padding: 130px 30px;
  width: 700px;
  margin-left: auto;
  margin-right: auto;
  text-align: left;
  position: relative;
}

.CustomerAuth__heading {
  font-size: 24px;
  font-weight: 500;
  padding-bottom: 15px;
}

.CustomerAuth__credential {
  display: block;
  position: relative;
  margin-bottom: 15px;
  border-radius: 3px;
}

.CustomerAuth__input {
  height: 60px;
  padding: 24px 10px 20px;
  border: 0px;
  font-size: 18px;
  background: #fff;
  width: 100%;
}

.CustomerAuth__submit {
  float: right;
}

.error {
  display: block;
  font-size: 15px;
  padding: 10px;
  position: relative;
  min-height: 2.64286em;
  background: #fbefee;
  color: #c23628;
}
button.button-quantity {
  background-color: #fff0ce;
  -webkit-appearance: media-slider;
  border: 1px solid #788188 !important;
}

.quantity-button-1 {
  padding-right: 0 !important;
}
.quantity-button-2 {
  padding-left: 0 !important;
}
.quantity-label {
  padding-right: 0 !important;
  padding-left: 0 !important;
  /* background-color: #efefef; */
}
.border-2 {
  border-top: 1px solid #dee2e6 !important;
  border-bottom: 1px solid #dee2e6 !important;
}
.tarjeta-checkbox {
  margin-top: 10px;
  margin-bottom: 10px;
  display: flex;
  align-items: center;
}
.checkbox-input {
  height: 25px;
  width: 25px;
}

.check1 {
  position: absolute;
  right: 33px;
  display: inline !important;
}
.input-envio{
    width: 100%;
    margin: 10px 0;
    padding: 5px;
    border: 1px solid #d9d5ca;
    border-radius: 5px;
}

@media only screen and (max-width: 426px) {
  .check1 {
    right: 65px;
  }
}
@media only screen and (max-width: 376px) {
  .check1 {
    right: 40px;
  }
}
@media only screen and (max-width: 321px) {
  .check1 {
    right: 10px;
  }
}

.quantity1 {
  position: absolute;
  right: 33px;
  display: inline !important;
  border: 1px solid black;
  height: 30px;
  width: 30px;
  border-radius: 100%;
  margin-top: 5px;
}

button.less {
  text-align: center;
  padding: 3px;
  font-weight: 900;
  box-shadow: none !important;
}
button.plus {
  text-align: center;
  padding: 3px;
  font-weight: 900;
  box-shadow: none !important;
}
button.agregar {
  box-shadow: none !important;
}
.row-pad {
  padding: 0 15px;
  margin-bottom: 10px;
}
.wrapper-button {
  display: flex;
  width: 100%;
  height: 40px;
  box-shadow: 0 1px 2px rgba(0, 0, 0, 0.3);
}
button {
  border: none;
}
